import { React, useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";

const Navigation = () => {
  const refs = useRef([]);
  const location = useLocation();
  const [selectedOption, setSelectedOption] = useState(0);

  useEffect(() => {
    // hightlights selected option when page is firstly loaded
    switch (location.pathname) {
      case "/":
        highlight(0);
        break;
      case "/o-polepsovne":
        highlight(1);
        break;
      case "/akce":
        highlight(2);
        break;
      case "/kontakty":
        highlight(3);
        break;
      case "/svatba":
        highlight(4);
        break;
      default:
        highlight(-1);
        break;
    }
  }, [location.pathname]);

  useEffect(() => {
    //lame solution to make template navbar work (for now) - TODO
    const script3 = document.createElement("script");
    script3.src = "assets/js/functions.js";
    script3.defer = true;
    document.body.appendChild(script3);
  }, []);

  // hightligts current page in navbar
  function highlight(id) {
    if (selectedOption != -1)
      refs.current[selectedOption].classList.remove("active");
    else console.log(selectedOption);
    if (id != -1) refs.current[id].classList.add("active");
    setSelectedOption(id);
  }

  return (
    <header
      id="navbar-spy"
      className="header header-1 header-transparent header-bordered header-fixed height-0"
    >
      <nav id="primary-menu" className="navbar navbar-fixed-top min-height-50">
        <div className="container">
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle collapsed margintop-6"
              data-toggle="collapse"
              data-target="#navbar-collapse-1"
              aria-expanded="false"
            >
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
            <Link
              className="logo posabs logo-z-index"
              to="/"
              onClick={(e) => highlight(0)}
            >
              <img
                className="logo-light logo-pd"
                src={`${process.env.PUBLIC_URL}/assets/images/logo/logo-light.png`}
                alt="Polepšovna ducha Logo"
              />
              <img
                className="logo-dark logo-pd logo-bg"
                src={`${process.env.PUBLIC_URL}/assets/images/logo/logo-dark.png`}
                alt="Polepšovna ducha Logo"
              />
            </Link>
          </div>

          <div className="module-container pull-right height-50 pd-top-0 margintop-6">
            <div className="module">
              <div className="module-icon line-height-50">
                <a
                  href="https://www.facebook.com/polepsovnaducha/"
                  target="_blank"
                >
                  <i className="fa fa-facebook font-size-18px"></i>
                </a>
                <span className="title">Facebook navigation</span>
              </div>
            </div>
            <div className="module">
              <div className="module-icon line-height-50">
                <a
                  href="https://www.instagram.com/polepsovnaducha/"
                  target="_blank"
                >
                  <i className="fa fa-instagram font-size-18px"></i>
                </a>
                <span className="title">Instagram navigation</span>
              </div>
            </div>
          </div>
          <div
            className="collapse navbar-collapse pull-right"
            id="navbar-collapse-1"
          >
            <ul className="nav navbar-nav nav-pos-right navbar-left">
              <li
                id="navbar-home"
                onClick={(e) => highlight(0)}
                ref={(el) => (refs.current[0] = el)}
              >
                <Link to="/" className="menu-item line-height-50">
                  Aktuálně
                </Link>
              </li>
              <li
                id="navbar-discgolf"
                class="has-dropdown"
                onClick={(e) => highlight(1)}
                ref={(el) => (refs.current[1] = el)}
              >
                <Link
                  to="/o-polepsovne"
                  className="menu-item line-height-50 dropdown-toggle menu-item"
                  data-toggle="dropdown"
                >
                  O Polepšovně
                </Link>
                {/* <ul class="dropdown-menu">
                  <li>
                    <Link to="/o-polepsovne#polepsovna">Polepšovna ducha</Link>
                  </li>
                  <li>
                    <Link to="/o-polepsovne/#pivovar">Pivovar</Link>
                  </li>
                  <li>
                    <a href="/o-polepsovne/#pivovar">YO</a>
                  </li>
                </ul> */}
              </li>
              <li
                id="navbar-beer"
                onClick={(e) => highlight(2)}
                ref={(el) => (refs.current[2] = el)}
              >
                <Link to="/akce" className="menu-item line-height-50">
                  Akce
                </Link>
              </li>
              <li
                id="navbar-contact"
                onClick={(e) => highlight(3)}
                ref={(el) => (refs.current[3] = el)}
              >
                <Link to="/kontakty" className="menu-item line-height-50">
                  Kontakty
                </Link>
              </li>
              <li
                id="navbar-contact"
                onClick={(e) => highlight(4)}
                ref={(el) => (refs.current[4] = el)}
              >
                <Link to="/svatba" className="menu-item line-height-50">
                  Svatby a soukromé akce
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navigation;
