import { React, useEffect, useState } from "react";

const EventImage = (props) => {
  return (
        <div className="dish-panel frow dish-panel-modifier">
            <div className="dish--img full-width height-auto">
                <img className="width-auto" src={props.img} alt={props.name}/>
                <div className="dish--overlay">
                    <a className="dish-popup" data-toggle="modal" data-target={`#eventPopup${props.id}`}><i className="fa fa-search-plus"></i></a>
                    <div className="modal fade" tabIndex="-1" role="dialog" id={`eventPopup${props.id}`}>
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"><i className="fa fa-times"></i></button>
                                    <div className="row reservation">
                                        <div className="col-xs-12 col-sm-12 col-md-12">
                                            <div className="img-popup">
                                                <img src={props.img} alt={props.name}/>
                                            </div>
                                        </div>
                                    </div>
                                    {/* .row end */}
                                </div>
                            </div>
                            {/* /.modal-content */}
                        </div>
                        {/* /.modal-dialog */}
                    </div>
                    {/* /.modal */}
                </div>
            </div>
        </div>
  );
};

export default EventImage;
