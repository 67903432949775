import {React} from "react"

function Footer() {
  return (
      <footer id="footer" className="footer footer-6 text-center">
        {/* Widget Section
        ============================================= */}
        <div className="footer--widget text--center padding-bottom-30px">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-3 col-md-4">
                <div className="footer--widget-content">
                  <h3>Adresa</h3>
                  <p>Polepšovna ducha, z.s.<br/>IČO: 26565340<br/>Husinec, Řež 042<br/>250 68 Řež</p>
                </div>
              </div>
              {/* .col-md-3 end */}
              <div className="col-xs-12 col-sm-6 col-md-4">
                <div className="footer--reservation">
                  <div className="divider--shape-10up"/>
                  <h3>Otevírací doba</h3>
                  <div className="divider--shape-11"/>
                  <div className="footer--reservation-wrapper">
                    <ul className="list-unstyled">
                      <li>Pátek <span>16:00 – 22:00</span></li>
                      <li>Sobota <span>15:00 – 22:00</span></li>
                      <li>Neděle <span>15:00 – 20:00</span></li>
                    </ul>
                  </div>
                  <div className="divider--shape-10down"></div>
                </div>
              </div> {/* .col-md-3 end */}
              <div className="col-xs-12 col-sm-3 col-md-4">
                <div className="footer--widget-content">
                  <h3>Provoz</h3>
                  <p>Daniela Tallová<br/>+420 775 487 225<br/>info@polepsovnaducha.cz</p>
                </div>
              </div>
              {/* .col-md-3 end */}
            </div>
          </div> {/* .container end */}
        </div>
        {/* .footer-widget end */}
        {/*Social
        ============================================= */}
        {/* Copyrights ============================================= */}
        {/* .footer-copyright end */}
      </footer>
  );
}

export default Footer;
