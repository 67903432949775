import {React, useEffect, useState} from "react"
import GalleryImg from "../elements/GalleryImg";

function Gallery(props) {

    useEffect(() => {

        const script3 = document.createElement('script');
            script3.src = "assets/js/functions.js";
            script3.defer = true;
            document.body.appendChild(script3);

    }, []);
  return (
    
    <section id="portfolio" className="portfolio portfolio-gallery portfolio-5col pt-80 bg-white">
    <div className="container-fluid pr-0 pl-0">
        <div id="portfolio-all" className="row-no-padding">
            {/* Portfolio #1 */}
           {props.imgs.map((item, id) => (
            <GalleryImg key={id} srcSmall={process.env.PUBLIC_URL + item.srcSmall} srcFull={process.env.PUBLIC_URL + item.srcFull} name={item.name}/>
           ))}
        </div>
        {/* .row end */}
        {/* .row end */}
    </div>
    </section>
            
  );
}

export default Gallery;
