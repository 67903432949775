import { React, useEffect, useState } from "react";
import resources from "../../resources.json";
import Beer from "../elements/Beer";
import MainBanner from "../elements/MainBanner";
import EventList from "../elements/EventList";

function Events(props) {
  useEffect(() => {
    document.title = 'Události';
  }, []);
  return (
    <>
      <MainBanner
        imgSrc="assets/images/banners/garden.jpeg"
        subheader=""
        mainHeader="Události"
      />

      <EventList setEvent={props.setEvent} />
    </>
  );
}

export default Events;
