import { React, useEffect, useState } from "react";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import EventImage from "./EventImage";
import resources from "../../resources.json";

function EventList(props) {
  const [culturalEvents, setCulturalEvents] = useState([]);

  const fetchFacebookEvents = () => {
    axios
      .get(`/facebookevents`)
      .then((res) => {
        setCulturalEvents(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  function setEvent(event) {
    props.setEvent(event);
  }

  useEffect(() => {
    // console.log(resources.events);
  }, []);

  return (
    <div>
      <section id="shop2" class="shop shop-4 bg-white">
        <div class="container">
          <div class="row clearfix">
            <div class="col-xs-12 col-sm-12 col-md-8 col-md-offset-2">
              <div class="heading heading-3 mb-30 mt-10 text--center">
                <h2 class="heading--title mb-0">Plánované události</h2>
                <div class="divider--shape-4"></div>
              </div>
            </div>
          </div>
          <div id="shop-all" class="row">
            {resources.events.length > 0 ? (
              resources.events.map((event, index) => (
                <div class="col-xs-12 col-sm-6 col-md-4 productFilter filter-desserts filter-soup">
                  <div class="product-item">
                    <div class="product--img">
                      <img src={event.cover} alt={event.name} />
                      <div class="product--hover">
                        <div class="product--action">
                          <a href={`${event.facebookEventLink}`} target="_blank">Více</a>
                        </div>
                      </div>
                    </div>
                    <div class="product--content">
                      <div class="product--title">
                        <h3>
                          <a href="#">{event.name}</a>
                        </h3>
                      </div>
                      <div class="product--price">
                        <span>{event.startTime}</span>
                      </div>
                      <div class="mt-20 elipsis">
                        <p>{event.description}</p>
                      </div>
                      <a href={`${event.facebookEventLink}`} target="_blank">Více...</a>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p className="heading--desc">
                V nejbližší době se nekonají žádné události.
              </p>
            )}
          </div>
        </div>
      </section>
    </div>
  );
}

export default EventList;
