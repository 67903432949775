import "./App.css";
import { React, useState, Script } from "react";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import PageNotFound from "./components/pages/PageNotFound";
import Map from "./components/sections/Map";
import Home from "./components/pages/Home";
import About from "./components/pages/About";
import Events from "./components/pages/Events";
import Footer from "./components/sections/Footer";
import Contact from "./components/pages/Contact";
import Wedding from "./components/pages/Wedding";
import Navigation from "./components/elements/Navigation";
import EventDetail from "./components/pages/EventDetail";
import ScrollToTop from "./components/helper/ScrollToTop";
import ReactGA from "react-ga";

const App = () => {
  const actualInfo = "Zítra 24.12. zavřeno!"; //TODO\
  const [selectedEvent, setSelectedEvent] = useState(null);
  ReactGA.initialize("UA-102558442-1");

  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Navigation />
        <Routes>
          <Route path="/" element={<Home setEvent={setSelectedEvent} />} />
          <Route path="/o-polepsovne" element={<About />} />
          <Route
            path="/akce"
            element={<Events setEvent={setSelectedEvent} />}
          />
          <Route path="/kontakty" element={<Contact />} />
          <Route path="/svatba" element={<Wedding />} />
          <Route
            path="/detail/:slug"
            element={
              <EventDetail event={selectedEvent} setEvent={setSelectedEvent} />
            }
          />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
      <Map />
      <Footer />
    </div>
  );
};

export default App;
