import {React, useEffect} from "react";

function PageNotFound() {
    useEffect(() => {
        document.title = 'Stránka nenalezena';
      }, []);
    const imgSrc = "assets/images/banners/garden.jpeg"
    return (
        <section className="page-404 text-center bg-overlay bg-overlay-gradient mtop-100 bg3"
        style={{
            backgroundImage: `url(${imgSrc})`
          }}> {/*fullscreen pb-0*/}
            
    <div className="container">
        <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-6 col-md-offset-3">
                <h3>404</h3>
                <div className="clearfix"></div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-md-offset-3">
                <div className="divider--shape-4 mb-30"></div>
                <div className="clearfix"></div>
                <p className="mb-30 title--subtitle description404">Bohužel požadovanou stránku nemůžeme najít</p>
                <a className="btn btn--bordered btn--secondary btn--white" href="/">Domů</a>
            </div>
        </div>
        {/* .row end */}
    </div>
    {/* .cotainer end */}
</section>

    );
}

export default PageNotFound;