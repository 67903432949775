import { React, useEffect, useState } from "react";
import Gallery from "../sections/Gallery";
import resources from "../../resources.json";
import MainBanner from "../elements/MainBanner";
import Beer from "../elements/Beer";

const About = (props) => {
  useEffect(() => {
    document.title = 'O Polepšovně ';
  }, []);
  return (
    <>
      <MainBanner
        imgSrc="assets/images/banners/garden.jpeg"
        subheader=""
        mainHeader="O Polepšovně"
      />
      <section id="wedding" className="heading heading-1 menu menu-7 menu-banner bg-white text-center">
        <div className="container" id="polepsovna">
          <div className="row clearfix">
            <div className="col-xs-12 col-sm-12 col-md-8 col-md-offset-2">
              <div className="text--center">
                <h3 className="heading--title mb-0">Polepšovna ducha</h3>
                <div className="divider--shape-4"></div>
                <p className="heading--desc">
                  Hledáte místo k odpočinku a relaxaci po náročném týdnu? Tak to
                  byste měli navštívit Polepšovnu ducha, která se nachází jen
                  pár kilometrů za Prahou. V této oáze klidu a přírody se budete
                  cítit jako doma. Na zahradě rostou ovocné stromy a uvnitř
                  najdete ty nejširší úsměvy a nejrychleji načepované půllitry.
                  Od roku 2022 jsme se začali věnovat vaření vlastních pivních
                  specialit a postupně se snažíme postavit pivovar přímo u nás v
                  Řeži. A co se týče zábavy, pořádáme pravidelně koncerty,
                  divadla, kvízy a další kulturní a sportovní akce. Tak
                  neváhejte a přijďte si užít pohlazení na duchu. 🍻
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-8 col-md-offset-2">
          <Gallery imgs={resources.aboutPD} />
        </div>
        <div className="container pb-70" id="pivovar">
          <div className="row clearfix">
            <div className="col-xs-12 col-sm-12 col-md-8 col-md-offset-2">
              <div className="text--center">
                <h3 className="heading--title mb-0">Pivovar</h3>
                <div className="divider--shape-4"></div>
                <p className="heading--desc">
                  Celý tenhle pivovarský projekt se zrodil v hlavě naší místní
                  rodačky Daniely Tallové již v roce 2020. Ale teprve v
                  následujícím roce se tyto plány začaly propojovat s úžasným
                  prostorem Polepšovna ducha, když se do plánování zapojil i
                  Tomáš Těthal. První pivo pod značkou Polepšovna ducha jsme
                  uvařili na začátku roku 2022 v pražském pivovaru Moucha, který
                  je spoluvlastněný Danielou. Od té doby jsme vytvořili řadu
                  zajímavých svrchně kvašených piv a postupně jsme si s našimi
                  pivy našli cestu na výčep i mimo prostor Polepšovny. Naším
                  cílem je vybudovat vlastní pivovar přímo v Řeži. Než se nám
                  tato dlouhá cesta podaří, máme v pivovaru Moucha vlastní dva
                  CKT tanky, do kterých vaříme základ naší značky.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="col-xs-12 col-sm-12 col-md-8 col-md-offset-2">
          <Gallery imgs={resources.discgolf} />
        </div> */}

        <div className="container" >
          <div className="row clearfix">
            <div className="col-xs-12 col-sm-12 col-md-8 col-md-offset-2 ">
              <div className="heading heading-1 mb-50 text--center">
                <h2 className="heading--title mb-0">Všechna piva</h2>
                <div className="divider--shape-4"></div>
              </div>
            </div>
          </div>
          <div class="row pb-100">
            {resources.beers.map((item, id) => (
              <Beer
                key={id}
                id={id}
                srcSmall={process.env.PUBLIC_URL + item.srcSmall}
                srcFull={process.env.PUBLIC_URL + item.srcFull}
                name={item.name}
                descriptionShort={item.descriptionShort}
                description={item.description}
                header={item.header}
                abv={item.abv}
                ibu={item.ibu}
                ebc={item.ebc}
              />
            ))}
          </div>
        </div>

        <div className="container pb-70">
          <div className="row clearfix">
            <div className="col-xs-12 col-sm-12 col-md-8 col-md-offset-2">
              <div className="text--center">
                <h3 className="heading--title mb-0">Discgolf</h3>
                <div className="divider--shape-4"></div>
                <p className="heading--desc">
                  Discgolf jejíž cílem je projít vytyčenou trasu s co nejmenším
                  počtem hodů diskem
                  <br />
                  Trasu tvoří 5 jamek, z nichž každá začíná výhozištěm a končí
                  discgolfovým košem. Další hod hráč provádí z místa, kde disk
                  zůstal po předchozím hodu ležet. Pro každý hod může hráč
                  použít jiný disk.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row padding-top-30px">
            <div className="col-xs-12 col-sm-12 col-md-12">
              <img
                className="full-size"
                src="assets/images/discgolf/full/discgolf_layout.jpg"
                alt="Discgolf map"
              />
            </div>
          </div>
          <div className="row padding-top-30px">
            <div className="col-xs-12 col-sm-12 col-md-8 col-md-offset-2">
              <h3 className="warning">Upozornění:</h3>
              <p className="heading--desc">
                Hráč je povinen se před každým hodem ujistit, že v možné dráze
                letu disku není žádná osoba nebo zvíře. Hráč plně odpovídá za
                svůj odhozený disk.
                <br />
                Vstup do prostoru hřiště je na vlastní nebezpečí.
              </p>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-8 col-md-offset-2">
          <Gallery imgs={resources.discgolf} />
        </div>
      </section>
    </>
  );
};

export default About;
