import { React, useEffect, useState } from "react";

function Beer(props) {
  return (
    <div class="col-xs-12 col-sm-6 col-md-6 mt-20">
      <div class="dishes-wrapper pb-20">
        <div class="dish-panel">
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <div class="dish--content">
                <span class="dish--price">{props.header}</span>
                <h3 class="dish--title mb-10">{props.name}</h3>
                <p class="dish--desc">{props.description}</p>
              </div>
              <div class="widget widget-tags">
                <div class="widget--content">
                  <a href="#">{props.abv} %</a>
                  <a href="#">{props.ibu} IBU</a>
                  <a href="#">{props.ebc} EBC</a>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <div class="dish--img">
                <div class="divider--shape-left"></div>
                <img src={props.srcSmall} alt={props.name} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Beer;
