import {React, useEffect, useState} from "react"

function Map(props) {

  return (
    <section id="map" className="google-maps pb-0 text--center">
      <div className="container">
        <div className="row clearfix">
          <div className="col-xs-12 col-sm-12 col-md-8 col-md-offset-2">
            <div className="heading heading-1 mb-50 text--center">
              <h2 className="heading--title mb-0">Kde nás najdete</h2>
              <div className="divider--shape-4" />
              <p className="heading--desc">
                Můžete se k nám dostat autem (parkování možné v areálu
                Polepšovny), vlakem nebo autobusem z Prahy a Kralup nad Vltavou
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid pr-0 pl-0">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 pr-0 pl-0">
            <iframe
              title="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1277.5930496300632!2d14.36264135820573!3d50.17635671261862!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470bc1c6dcbf62e1%3A0x440b6fa733d5aa6d!2sPolep%C5%A1ovna+ducha!5e0!3m2!1scs!2scz!4v1499327253824"
              width="99%"
              height="350px"
              frameBorder={0}
              style={{ border: 0 }}
              allowFullScreen=""
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Map;
