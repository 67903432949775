import {React, useEffect, useState} from "react"

function GalleryImg(props) {

//assets/images/gallery/full/1.jpg
  return (
    
            <div className="col-xs-6 col-sm-4 col-md-5ths portfolio-item">
                <div className="portfolio--img">
                    <img src={props.srcSmall} alt={props.name}/>
                    <div className="portfolio--hover">
                        <div className="portfolio--action portfolio--hover-bg-transparent">
                            <div className="pos-vertical-center full-size">
                                <div className="portfolio--zoom portfolio-icon full-size">
                                    <a className="img-gallery-item full-size" href={props.srcFull} title={props.name}>
                                        <div className="full-size flex-center">
                                            <div className="fa fa-search-plus gallery-plus-icon"/>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/* .Portfolio-action end */}
                    </div>
                    {/* .Portfolio-hover end */}
                </div>
                {/* .Portfolio-img end */}
            </div>
            
  );
}

export default GalleryImg;
