import { React, useEffect, useState } from "react";
import MainBanner from "../elements/MainBanner";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
import EventImage from "../elements/EventImage";
import resources from "../../resources.json";

const EventDetail = (props) => {
  const params = useParams();

  useEffect(() => {
    // if (props.event === null) getEventFromBE();
  }, []);

  const getEventFromBE = () => {
    console.log(params.slug);
    /* axios
      .get(`/getfacebookevent`, {params: {id: params.slug}})
      .then((res) => {
        props.setEvent(res.data);
      })
      .catch((error) => {
        console.error(error);
      }); */
  };

  return (
    <>
      {resources.events[params.slug] ? (
        <>
          <MainBanner
            imgSrc={props.event.img}
            subheader={`${props.event.date.day}. ${props.event.date.month.name} ${props.event.date.time}`}
            mainHeader={props.event.name}
            darker
          />
          <section
            id="events"
            className="banner banner-7 text-center pd-bottom-0"
          >
            <div className="container">
              <div className="row clearfix">
                <div className="col-xs-12 col-sm-12 col-md-8 col-md-offset-2">
                  <div className="heading heading-1 mb-50 text--center">
                    <h2 className="heading--title mb-0">Informace</h2>
                    <div className="divider--shape-4"></div>
                  </div>
                </div>
              </div>
              <div className="row heading">
                <p className="heading--desc">{props.event.description}</p>
              </div>
            </div>
            <div
              id="menu61"
              className="menu menu-5 menu-6 bg-white text-center"
            >
              <div className="flex-row-justify-content-around mg-top-50px">
                <div className="width-500px">
                  <EventImage
                    img={props.event.img}
                    name={props.event.name}
                    id={props.event.id}
                  />
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <MainBanner
          imgSrc="../assets/images/banners/pd_main_2.jpeg"
          mainHeader="Je nám líto, ale akci nemůžeme najít"
        />
      )}
    </>
  );
};

export default EventDetail;
