import { React, useEffect, useState } from "react";

const MainBanner = (props) => {
  return (
    <section
        id="page-title"
        className="page-title bg-overlay bg-overlay-gradient bg3"
        style={{
          backgroundImage: props.darker ? `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${props.imgSrc})`
                                        : `url(${props.imgSrc})`
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="title title-4 text-center pd-bottom-0 main-banner-title-pd">
                <div className="title--content">
                  <div className="title--subtitle">{props.subheader}</div>
                  <div className="title--heading">
                    <h1>{props.mainHeader}</h1>
                  </div>
                </div>
                <div className="clearfix"></div>
                <div className="divider--shape-1down divider--shape-gray"></div>
              </div>
            </div>
          </div>
        </div>
  </section>    
  );
};

export default MainBanner;
