import { React, useEffect, componentDidMount, Component } from "react";
import MainBanner from "../elements/MainBanner";

const Contact = () => {
  useEffect(() => {
    document.title = 'Kontakty';
  }, []);
  return (
    <>
      <MainBanner
        imgSrc="assets/images/banners/garden.jpeg"
        subheader=""
        mainHeader="Kontakty"
      />

      <section id="team1" class="team team-1 team-shadow bg-white heading heading-1">
      <div className="container pb-30">
          <div className="row clearfix">
            <div className="col-xs-12 col-sm-12 col-md-8 col-md-offset-2">
              <div className="text--center">
                <h3 className="heading--title mb-0">Provoz</h3>
                <div className="divider--shape-4"></div>
                <p className="heading--desc">
                  Daniela Tallová
                  <br />
                  +420 775 487 225
                  <br />
                  info@polepsovnaducha.cz
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-xs-12 col-sm-6 col-md-4 col-md-offset-4">
              <div class="member">
                <div class="member-img">
                  <img src="assets/images-pd/contact/danka.jpg" alt="member"></img>
                  {/* <div class="member-overlay">
                    <div class="member-hover">
                      <div class="pos-vertical-center">
                        <div class="member-social">
                          <a href="#">
                            <i class="fa fa-facebook"></i>
                          </a>
                          <a href="#">
                            <i class="fa fa-twitter"></i>
                          </a>
                          <a href="#">
                            <i class="fa fa-instagram"></i>
                          </a>
                        </div>
                        <div class="member-desc">
                          <p>
                            Passion for cooking is in his genes. He grew up
                            appreciating outstanding food and service at an
                            early age.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div class="member-info">
                  <h5>Daniela Tallová</h5>
                  <h6>Provozní</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
