import { React, useEffect, componentDidMount, Component } from "react";
import MainBanner from "../elements/MainBanner";
import Gallery from "../sections/Gallery";
import resources from "../../resources.json";

const Wedding = () => {
  useEffect(() => {
    document.title = 'Svatby a soukromé události';
  }, []);
  return (
    <>
      <MainBanner
        imgSrc="assets/images/banners/garden.jpeg"
        subheader=""
        mainHeader="Svatby a soukromé akce"
      />
      <section id="wedding" className="heading heading-1 bg-white pb-90">
        <div className="container">
          <div className="row clearfix">
            <div className="col-xs-12 col-sm-12 col-md-8 col-md-offset-2">
              <div className="text--center">
                <h3 className="heading--title mb-0">Svatby a soukromé akce</h3>
                <div className="divider--shape-4"></div>
                <p className="heading--desc">
                  Hledáte ideální místo pro vaši svatbu, oslavu narozenin nebo
                  teambuilding? Polepšovna ducha je tím pravým místem pro vás!
                  Nabízíme nejen skvělé zázemí, ale také profesionální obsluhu a
                  kvalitní catering. Naše oáza klidu a přírody vám poskytne
                  perfektní prostředí pro vaši událost. Ať už si přejete
                  romantickou svatbu pod širým nebem, nebo oslavu s vašimi
                  blízkými, u nás se vám to splní. A co se týče cateringu, naše
                  jídlo je připraveno s láskou a pečlivostí. Můžeme vám
                  nabídnout širokou škálu možností, které budou vyhovovat vašim
                  přáním a potřebám. Vaši hosté budou nadšeni chutnými pokrmy a
                  vy se budete moci naplno věnovat oslavě. Pro více informací
                  nás kontaktuje na{" "}
                  <a href="mailto:info@polepsovnaducha.cz">
                    info@polepsovnaducha.cz
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-8 col-md-offset-2">
          <Gallery imgs={resources.eventImages} />
        </div>
        <div className="container">
          <div className="row clearfix">
            <div className="col-xs-12 col-sm-12 col-md-8 col-md-offset-2">
              <div className="text--center">
                <h3 className="heading--title mb-0">Catering</h3>
                <div className="divider--shape-4"></div>
                <p className="heading--desc">
                  Hledáte ideální místo pro vaši svatbu, oslavu narozenin nebo
                  teambuilding? Polepšovna ducha je tím pravým místem pro vás!
                  Nabízíme nejen skvělé zázemí, ale také profesionální obsluhu a
                  kvalitní catering. Naše oáza klidu a přírody vám poskytne
                  perfektní prostředí pro vaši událost. Ať už si přejete
                  romantickou svatbu pod širým nebem, nebo oslavu s vašimi
                  blízkými, u nás se vám to splní. A co se týče cateringu, naše
                  jídlo je připraveno s láskou a pečlivostí. Můžeme vám
                  nabídnout širokou škálu možností, které budou vyhovovat vašim
                  přáním a potřebám. Vaši hosté budou nadšeni chutnými pokrmy a
                  vy se budete moci naplno věnovat oslavě. Pro více informací
                  nás kontaktuje na{" "}
                  <a href="mailto:info@polepsovnaducha.cz">
                    info@polepsovnaducha.cz
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-8 col-md-offset-2">
          <Gallery imgs={resources.catering} />
        </div>
      </section>
    </>
  );
};

export default Wedding;
